export const queryKeys = {
  depositeBalance: 'deposite_balance',
  overdraftHistory: 'overdraft_history',
  boardTotalInfo: 'board_total_info',
  chart: 'chart',
  transactions: 'transactions',
  transactionId: 'transaction_id',
  transactionLogId: 'transaction_log',
  reports: 'reports',
  terroristsList: 'terrorists_list',
  sanctionList: 'sanction_list',
  checkSanctionIn: 'check_sanction_in',
  finmonCheckList: 'fin_mon_check_list',
  companiesList: 'companies_list',
  compantyItem: 'company_item',
  merchantSettings: 'merchantSettings',
  merchantSettingsList: 'merchantSettingsList',
  merchantDocuments: 'merchantDocuments',
  extraData: 'extraData'
};
