import ACLGuard from '@comp/ACLGuard';
import Loadable from '@root/routes/Loadable';
import { lazy } from 'react';

const MerchantList = Loadable(lazy(() => import('@pages/merchant/index')));
const MerchantModelId = Loadable(lazy(() => import('@pages/merchant/id')));
const MerchantModelIdUpdate = Loadable(
  lazy(() => import('@pages/merchant/update'))
);
const MerchantCommissions = Loadable(
  lazy(() => import('@pages/merchant/commissions'))
);

const MerchantModelCreate = Loadable(
  lazy(() => import('@pages/merchant/create'))
);

const MerchantTokenUpdate = Loadable(
  lazy(() => import('@pages/merchant/_token/update'))
);

const MerchantDepositUpdate = Loadable(
  lazy(() => import('@pages/merchant/_deposit/update'))
);

const MerchantCreateCommission = Loadable(
  lazy(() => import('@pages/merchant/commissions/commissionCreate'))
);
const MerchantNotifications = Loadable(
  lazy(() => import('@pages/merchant/notifications'))
);
const MerchantSettings = Loadable(
  lazy(() => import('~/pages/merchant/merchantSettings'))
);
const MerchantDocuments = Loadable(
  lazy(() => import('~/pages/merchant/documents'))
);
const MerchantDocumentsCreateAndUpdate = Loadable(
  lazy(() => import('~/pages/merchant/documents/CreateAndUpdateDocuments'))
);

export const merchantsRoute = {
  path: 'merchants',
  children: [
    {
      index: true,
      element: (
        <ACLGuard can="read">
          <MerchantList />
        </ACLGuard>
      )
    },
    {
      path: 'create',
      element: (
        <ACLGuard can="create">
          <MerchantModelCreate />
        </ACLGuard>
      )
    },
    {
      path: 'id/:id',
      element: (
        <ACLGuard can="details">
          <MerchantModelId />
        </ACLGuard>
      )
    },
    {
      path: 'id/:id/update',
      element: (
        <ACLGuard can="update">
          <MerchantModelIdUpdate />
        </ACLGuard>
      )
    },

    {
      path: 'token/:id',
      element: (
        <ACLGuard can="getMerchantKey">
          <MerchantTokenUpdate />
        </ACLGuard>
      )
    },
    {
      path: 'deposit/:id',
      element: (
        <ACLGuard can="depositLimitEdit">
          <MerchantDepositUpdate />
        </ACLGuard>
      )
    },
    {
      path: 'commissions/:id',
      element: (
        <ACLGuard can="create">
          <MerchantCommissions />
        </ACLGuard>
      )
    },
    {
      path: 'create/:id',
      element: (
        <ACLGuard can="create">
          <MerchantCreateCommission />
        </ACLGuard>
      )
    },
    {
      path: 'notifications/:id',
      element: (
        <ACLGuard can="create">
          <MerchantNotifications />
        </ACLGuard>
      )
    },
    {
      path: 'settings/:id',
      element: (
        <ACLGuard can="create">
          <MerchantSettings />
        </ACLGuard>
      )
    },
    {
      path: 'documents/:id',
      element: (
        <ACLGuard can="create">
          <MerchantDocuments />
        </ACLGuard>
      )
    },
    {
      path: 'documents/form/:id',
      element: (
        <ACLGuard can="create">
          <MerchantDocumentsCreateAndUpdate />
        </ACLGuard>
      )
    }
  ]
};
