const buttons = {
  'Recovery button': 'Recovery',
  'Change button': 'Change',
  'Back button': 'Back',
  'Create button': 'Create',
  'Save button': 'Save',
  'Update button': 'Update',
  'Delete button': 'Delete',
  'Search button': 'Search',
  'Clear button': 'Clear',
  'Copy button': 'Copy',
  'Cancel button': 'Cancel',
  'Apply button': 'Apply',
  'Ok button': 'Ok',
  'Back to Home': 'To main page',
  'status-city': 'Info',
  'cancel-city': 'Cancel',
  'register-city': 'Register',
  cancel_overdraft: 'Cancel overdraft',
  receipt: 'Receipt',
  Reference: 'Reference',
  clear_filters: 'Reset filters',
  account_report: 'Бухгалтерський звіт',
  balancereportprocessor: 'Звіт по балансам марчантів',
  dailytransactionsstatus: 'Звіт по операціям(денний)',
  dovidka: 'Звіт реєстру довідок',
  nbuform1merchantreportprocessor: 'Форма1 НБУ',
  nbuform2merchantreportprocessor: 'Форма2 НБУ до 01.08.2024',
  nbuform3merchantreportprocessor: 'Форма3 НБУ',
  new_default: 'Стандартний звіт(основний)',
  to10nbu: 'Топ-10 ЕПЗ для НБУ',
  merchantdailyreport: 'Стандартний звіт',
  combine_report_no_trx: 'Зведений звіт Б/Т (бухгалтерія)',
  combine_report: 'Зведений звіт, повний (бухгалтерія)',
  decline_codes: 'Коди відмов по операціям',
  finmoncheckresult: 'Звіт Фін-Мон перевірки операцій',
  merchant_balances: 'Баланси мерчанта',
  merchant_fee: 'Комісії мерчанта',
  form2from010824v2reportprocessor: 'Форма2 НБУ з 01.08.2024',
  form5reportprocessor: 'Форма5 НБУ',
  nbuform4reportprocessor: 'Форма4 НБУ',
  over2percard2c: 'Статистика по картам >3 A2C',
  over2percard2a: 'Статистика по картам >2 C2A',
  standartreport: 'Стандартний звіт (обороти)',
  toshortpayaftergiveoutprocessor: 'Передчасне С2A після А2С'
};

const menu = {
  'Transaction menu': 'Transactions',
  FinMon: 'Resolutions FM',
  FinMonUpdate: 'Fin Monitoring Update',
  'Users menu': 'Users',
  'Role menu': 'Roles',
  'User Item': 'User info',
  'Banks menu': 'Banks',
  'Flow menu': 'Flows',
  'Gateway menu': 'Gateway',
  'Cascading menu': 'Cascad',
  'Cascading rules menu': 'Rules',
  'Cascading models menu': 'Models',
  'Terminals menu': 'Terminals',
  'Merchant menu': 'Merchants',
  'Description menu': 'Export docs',
  'Description menu custom': 'Export',
  'Description menu city24': 'Export city24',
  'Codes menu': 'Codes',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Reconciliation'
};

const text = {
  userId: 'User ID',
  Download_list_of_terrorists: 'Download list of terrorists',
  'Having an account': 'I already have an account',
  'Reset account password': 'Restore access',
  'Success update': 'Entry updated',
  'Success upload': 'Файл загружен',
  'Do you want to remove': 'Do you want to delete the entry? ',
  'Register account': 'Registration',
  'Success registration': 'You are successfully registered',
  'Success deleted': 'Entry deleted',
  'Reset title': 'Restore password',
  'Success recovery send token': 'Instructions sent to email',
  letter_pumb: 'Letter PUMB',
  letter_c2a: 'Letter C2A',
  contractName: 'Name of the contract',

  overdraft: 'Overdraft',
  available_balance: 'Available balance',
  given: 'Payments',
  owned: 'Own funds',
  collected: 'Accepted payments',

  creditBalance: 'Available balance',
  debitBalance: 'Own funds',
  given_amount: 'Payments',
  Balanc: 'Balance',
  collected_amount: 'Accepted payments',
  documents: 'Contracts',

  'Transactions List': 'Transactions list',
  'Transactions Item': 'Transaction info',
  returnTran: 'Return',
  'Sunction or Terrorist list': 'No matches were found.',
  'Active user':
    'You`ve been inactive for 15 minutes. The session was automatically terminated. Please log in again to continue',
  FinMonTableUser: 'User',
  FinMonTableDate: 'Date/time',
  FinMonTableComment: 'Comment',
  'For merch': 'For merchant',
  today: 'Today',
  yesterday: 'Yesterday',
  previousMonth: 'Previous Month',
  currentMonth: 'Current Month',
  startActionDate: 'Start of business relationship',

  idOperations: 'ID operations',
  reportType: 'Select a report',
  'Return to the balance': 'Return to the balance',
  'Without changing the balance': 'Without changing the balance',
  'Register Return': 'Register a return',
  autoLogout: 'Auto exit via ',
  'Two-factor authentication': 'Two-factor authentication',

  'Users List': 'Users list',
  'User Item Update': 'User update',
  'User Item Create': 'Creating a user',
  'Roles List': 'Role list',
  'Role Item Create': 'Creating a role',
  'Role Item Update': 'Role update',

  'Terminals List': 'Terminal list',
  'Terminals Item Id': 'Terminal info',
  'Terminals Model Create': 'Creating new terminal',
  'Terminals Token Update': 'Keys update',
  'Terminals Item Update': 'Terminal update',
  'Terminal Item Id': 'Terminal info',
  'Do you want to update token': 'Do you really want to update a key?',

  'Merchant List': 'Merchants list',
  'Merchant Item Id': 'Merchant description',
  'Merchant Item Update': 'Merchant update',
  'Merchant Item Create': 'Creating a merchant',
  'Merchant Token Update': 'Merchant key update',
  'Merchant Commissions': 'Commissions',
  'Merchant Commissions External': 'External Commission',
  'Merchant Commissions Internal': 'Internal Commission',
  'Merchant Notifications': 'Notifications',
  'Merchant Notifications Email': 'Email',
  'Merchant Notifications Create': 'Create',
  'Merchant Percentage': 'Percentage',
  'Merchant Name': 'Merchant Name',
  'Merchant Settings': 'Merchant Settings',
  BalanceLoopBack: 'BalanceLoopBack',
  'Create Commissions': 'Create Commissions',
  'Update Commissions': 'Update Commissions',
  'FinMon Status': 'Status FM',
  'In processing': 'In processing',
  'Processing allowed': 'Processing allowed',
  'Ban on operations': 'Ban on operations',

  'Contract Number': 'Contract Number',
  'Contract Name': 'Contract Name',
  'Contract Date': 'Contract Date',
  'Act From': 'Act From',
  'Act Till': 'Act Till',
  'is current': 'Is Current',
  'Update Contract': 'Update Contract',
  'Create Contract': 'Create Contract',
  'Banks List': 'Banks list',
  'Banks Item Id': 'Bank information',
  'Bank Item Create': 'Adding a new bank',
  'Bank Item Update': 'Bank update',
  'Bank Deposit Update': 'Limit update',

  'Transactions Flow List': 'Transaction schemes list',
  'Transactions Flow Create': 'Create a transaction schema',

  'Gateway List': 'Gateway list',
  'Gateway Item Create': 'Creating a gateway',
  'Gateway Item': 'Gateway description',
  'Gateway Item Update': 'Gateway update',

  'Cascading Rules List': 'Rules list',
  'Cascading Models List': 'Model List',
  'Cascading Model Create': 'Creating a model',
  'Register payments': 'Register payments',
  'Check registry': 'Check registry',

  'Codes List': 'Codes list',
  'Codes Item Id': 'Code information',
  'Code Item Update': 'Code update',
  'Code Item Create': 'Creating the code',
  'Transactions Logs Item': 'Transaction logs',
  'Reconciliation List': 'Reconciliation',
  miss_in_bnk: 'Missing from the bank',
  miss_in_pt: 'Missing from the pytech',
  trn_not_registered: 'Not registered',
  miss_in_bnk2: 'Attention!!!',
  dateStart: 'Period from',
  dateEnd: 'Period to',
  tranTypeId: 'Type of operation',
  bankId: 'Bank',
  respCode: 'Status',
  amountFrom: 'Amount from',
  amountTo: 'Amount to',
  'card first 6 number': 'First 6 digits of the card',
  'card last 4 number': 'The last 4 digits of the card',
  tranId: 'Transaction ID',
  finMonCheck: 'Status Fin-mon',
  inputType: 'Payment type',
  frictionless: '3ds Scenario',
  sanListCheckStatus: 'Status of NSDC sanctions check',
  sanListCheckDate: 'Date/hour of verification under RNBO sanctions',
  terListCheckStatus: 'Status of terrorist list check',
  terListCheckDate: 'Date/time of check against the list of terrorists',
  Confirmation: 'Are you sure you want to proceed with this action?',
  returnBank: 'Return Bank',
  agreementDate: 'Agreement date',
  clientName: 'Client name',
  gateway: 'Bank',
  acsStatus: '3DS status',
  amount: 'Amount',
  approval: 'Authorization code',
  callbackUrl: 'Call back URL',
  fee: 'Fee',
  getewayRefNo: 'Bank ID',
  lang: 'Language',
  pan: 'Map',
  respCodeId: 'Response code',
  rrn: 'RRN',
  tranType: 'Type of operation',
  description: 'Trademark',
  status: 'Status',
  timezone: 'Time zone',
  comment: 'Comments',
  bankName: 'Bank',
  fields: 'Other fields',
  langEn: 'EN',
  langRu: 'RU',
  langUk: 'UA',
  external: 'Code',
  merchId: 'Merchant',
  Profile: 'Profile',
  Settings: 'Settings',
  Logout: 'Logout',
  Balance: 'Balance',
  'Own Founds': 'Own Founds',
  'Accepted Payments': 'Accepted Payments',
  'Own founds at the begiining of the day':
    'Own founds at the begiining of the day',

  'Export List': 'Экспорт файлов',
  'Download File': 'Download File',
  decreaseAmount: 'Reduction amount',
  'Overdraft Date': 'Rent in',
  generated_reports_list: 'List of available files',
  'Change Password': 'Change Password',
  'Bin List': 'Bin',
  'Invitation link': 'Ссылка для приглашения пользователя',
  'City24 Files List': 'Export city24 files',
  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',
  readiness: 'Readiness',

  'New Password': 'New Password',
  'Confirm New Password': 'Confirm New Password',
  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Request success send': 'Request success send',
  terrorists_list: 'Terrorists List',
  sanctions_list: 'Sanctions List',
  STATUS_NEW: 'NEW',
  STATUS_PROCESSING: 'PROCESSING',
  STATUS_FINISHED: 'FINISHED',
  STATUS_FAILED: 'FAILED',
  userName: 'User',
  fileName: 'File Name',
  gatewayRefNo: 'Bank-ID',
  Update: 'Update',
  'Last Update': 'Last Update',
  companyEmail: 'Company email',
  edrpou: 'ЄДРПОУ',
  accountNumber: 'Account number',
  bankMfo: 'MFO',
  mainPhone: 'Main Phone',
  city: 'City',
  address: 'Address',
  'Companies List': 'Companies List',
  'Companies Create': 'Create company',
  'Companies Success': 'Company create',
  'Companies Fail': 'Error',
  Company: 'Company',

  'In progress': 'In progress',
  Verified: 'In progress',
  'Found in lists': 'Found in lists',
  'Extra Data': 'Extra Data',
  disableClick: 'Switch off',
  enableClick: 'Turn on'
};

const fields = {
  orderBy: 'Order by',
  sortAscending: 'Ascending',
  sortDescending: 'Descending',
  uuid: 'ID',
  name: 'name',
  resolution: 'Resolution',
  createOn: 'created by',
  merchant: 'merchant',
  tranId: 'transaction id',
  tranType: 'type of operation',
  pan: 'card number',
  amount: 'amount',
  fee: 'commission',
  gateway: 'acquirer',
  respCode: 'response code',
  lang: 'language',
  editOn: 'updated',
  firstName: 'name',
  lastName: 'surname',
  birthDate: 'Birth Date',
  extId: 'External ID',
  placeOfBirth: 'Place of Birth',
  sanctions_date: 'Sanction date',
  fullName: 'Full name',
  middleName: 'Middle Name',
  inn: 'INN',
  phone: 'phone number',
  loginTries: 'login attempts',
  lastLogin: 'last login date',
  linkToken: 'key',
  role: 'role',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'role',
  'permissions field': 'permissions',
  'name bank field': 'bank name',
  depositLimit: 'deposit limit',
  commissions: 'Commissions',
  keyToken: 'keys',
  flowName: 'flow name',
  endpoint: 'entry point',
  env: 'environment',
  bank: 'bank',
  'name gateway field': 'gateway name',
  'Select value': 'select a value',
  type_operation: 'Type of transaction',
  notificationChannel: 'Notification channel',
  'Set deposit limit': 'Set limit',
  'Increase deposit limit': 'Increase limit',
  'Decrease deposit limit': 'Decrease limit',
  'Comment show': 'Show comment',
  'Select Date': 'Select Date',
  'File processing': 'File processing',
  checkStatusOk: 'Thanks for waiting. All cards from the file are valid',
  'Download file': 'Download file',
  'Invalid cards detected': 'Invalid cards detected',
  Loading: 'Loading',
  'Upload file': 'Upload file',
  cityRespCode: 'City24 status',
  company_email: 'Company Email',
  company_id: 'Company ID',
  transaction_count: 'Number of transactions',
  transaction_amount: 'Sum of all transactions',
  transaction_fee: 'Commission amount',
  is3ds: '3D-Secure enabled?',
  for_charge: 'Can be used for Charge?',
  is_apple_pay: 'Only for ApplePay?',
  is_google_pay: 'Only for GooglePay?',
  'Company Signatures': 'Staff',
  manager: 'Manager',
  'Company Fio Signatures': 'FIO',
  'Company Status Signatures': 'Status',
  'Company Job Signatures': 'Job ',
  'Company Active Signatures': 'Active',
  'Company NotActive Signatures': 'Not active',
  'Create Signatures': 'Create signatures',
  'Search file': 'Search file',
  CheckedFinMon: 'Checked, no additional solutions are required',

  terStatus: 'Status of terrorist list check',
  terCheck: 'Date/Time of terrorist list check',
  sanStatus: 'Status of NSDC sanctions check',
  sanCheck: 'Date/Time of NSDC sanctions check',
  'Block the user': 'Block the user?',
  enabled: 'Enabled'
};

const server = {
  'Token error': 'Token error',
  '404 error title': 'Page not found',
  '404 error description': "Sorry, that page can't be found"
};

const validationForm = {
  required: 'required',
  email: 'email',
  'field float': 'Поле должно быть целым или дробным числом',
  'field number': 'Поле должно быть целым числом',
  'Passwords must match': 'Passwords must match',
  'Error password format':
    'The password must be at least 8 characters long. Use uppercase and lowercase letters as well as numbers and special characters',
  'Error phone format': 'Phone must be in 380XXXXXXXXXXX format'
};

export const en = {
  'Forgot password': 'Forgot password',
  'Create new account': 'Create new account',
  'Login text': 'Login',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
